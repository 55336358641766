import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import gsap from 'gsap'
import { Link } from 'react-router-dom'



import Process from '../components/Process'
import Clients from '../components/Clients';
import TextAnimations from '../components/TextAnimations'
import Carousel from '../components/Carousel';
import Progressbar from '../components/Progressbar'
import AllServices from '../components/AllServices'


import video from '../images/video.mp4'
import ads from '../images/main_banner.jpg'

import wave from '../images/wave.png'
import innov from '../images/Innovation.png'
import ad from '../images/ad.jpg'
import about from '../images/about.png'
import bird from '../images/bird.png'
import sir from '../images/RAJESH_SIR_NEW.png'

import corner from '../images/corner.png'
import join from '../images/journey.png'
import imgOne from '../images/ad.jpg';
import marketing from '../images/blog/markting.jpg'
import idea from '../images/blog/idea.jpg'
import contact from '../images/contact.png'


// icons
import { HiArrowLongDown } from "react-icons/hi2";
import { FaLongArrowAltRight } from "react-icons/fa";
import { FaExternalLinkSquareAlt } from "react-icons/fa";
import { FaArrowRightLong } from "react-icons/fa6";
import { MdPartyMode } from "react-icons/md";

import Cards from '../components/Cards';
import BorderAnimation from '../components/BorderAnimation';
import Partners from '../components/Partners';
import Allprocess from '../components/Allprocess';
import TypingAnimation from '../components/TypingAnimation';
import Eye from '../components/Eye';
import WhyIcons from '../components/WhyIcons';
import AllProcessNew from '../components/AllProcessNew';
import Ball from '../components/Ball';
import AllServicesone from '../components/AllServicesone';




const Home = () => {

    useEffect(() => {
        AOS.init({
            duration: 1000, // You can set a global duration if needed
        });
    }, []);

    function topPage() {
        window.scroll(0.0)
    }



    return (
        <>
            <div className='overflow-visible'>

                <Ball />

                <div className='fixed top-0  -z-40'>
                    {/* <video className='w-full h-full  ' autoPlay playsInline loop muted>
                        <source src={video} className='w-full object-cover' type='video/mp4' />
                    </video> */}
                </div>

                {/*---------- header ------------*/}

                <section className=" w-full">
                    <div className="relative h-full w-full ">


                        {/* Front Text Content */}
                        {/* <div className="flex justify-center items-center w-full  font-[poppins] z-20 absolute bottom-4 md:bottom-36">
                            <div className="text-center">
                                <h1 className="font-bold text-[40px] md:text-[85px] tracking-[2px] text-white ">Innovative</h1>

                                <div className="mt-20 md:mt-28 flex flex-col items-center gap-y-8">
                                    <p className="slide-fade-animation flex space-x-3 text-[10px] md:text-[16px]">
                                        <span>We Shape</span>
                                        <span>Tomorrow's</span>
                                        <span>Brand</span>
                                        <span>Today</span>
                                    </p>

                                    <div className="flex justify-center items-center">
                                        <HiArrowLongDown className="font-bold text-[18px] md:text-[24px] text-white animate-subtle-bounce" />
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        {/* Background Image */}
                        <div className=" w-full h-full   z-10">
                            {/* <img src={ads} className="md:-mt-[7rem] w-full h-full object-cover" alt="wave background" /> */}

                            <video className='w-full h-full   md:-mt-[7rem]' autoPlay playsInline loop muted>
                                <source src={video} className='w-full h-full object-cover' type='video/mp4' />
                            </video>
                        </div>
                    </div>
                </section>


                {/*------- about -------*/}

                <section className='bg-white pt-10 md:pt-20  pb-10 md:pb-20' id='second-section' >
                    <div className='w-full px-4 md:w-[80%] mx-auto h-full font-sans' id='border-section'>

                        {/* <div className="flex relative flex-col mb-10">
                            <div className="border-t-4 border-black w-[10%] flex justify-center items-center"></div>
                            <div className="absolute flex justify-center items-center top-0  border-t border-black w-full "></div>
                        </div> */}

                        <div className="flex relative flex-col mb-4" data-aos="fade-right" data-aos-duration='1000'>
                            {/* Animated Border */}

                            <div className=" absolute top-0 flex  border-t-2 md:border-t-4 w-[10%] border-black "></div>
                            <div className=''>
                                <BorderAnimation />
                            </div>
                            <div className="absolute flex justify-center items-center top-0 border-t border-black w-full"></div>
                        </div>


                        {/* ABOUT Title */}
                        <div className='flex flex-col gap-y-1 items-start font-sans'>
                            <p className="text-gray-700 uppercase  tracking-wide font-sans text-[12px] md:text-[14px]">About</p>
                            <h2 className='text-[28px] md:text-[58px]  font-light tracking-[1px] font-sans' data-aos="flip-up" data-aos-duration='1000'>We, Humanize the Brand</h2>
                        </div>



                        <div className='flex flex-col gap-y-10 md:flex-row items-center justify-center mt-6 md:mt-0 '>

                            <div className='w-full md:w-[50%] mx-auto' data-aos-duration='1000' data-aos='fade-up'>
                                <div className="flex  h-full justify-start  text-white">
                                    <img src={about} className='w-full md:w-[90%] object-cover' alt='about' />
                                </div>
                            </div>

                            <div className='w-full md:w-[50%] mx-auto font-sans' data-aos-duration='3000' data-aos='fade-up'>

                                <div className='w-full md:px-10'>

                                    <div className="w-full flex flex-col  gap-y-2 md:gap-y-4">

                                        <p className="text-[20px] md:text-[28px] text-start font-light leading-[34px] text-gray-900">
                                            We don't just create ads - we build experiences that engage and drive results
                                        </p>
                                        <div className="border-t md:border-t-2 border-gray-700 w-full mt-2 md:mt-4 mb-2"></div>
                                        <p className="text-[14px] md:text-[16px] text-start leading-6 md:leading-7 text-black font-light">
                                            With over 18+ years of experience, we specialize in transforming ideas into memorable campaigns. Whether you're a startup or an established brand,
                                        </p>

                                        <div className='border-b border-gray-500 mt-4 md:mt-6 w-fit group'>
                                            <button className='flex items-center gap-x-6 md:gap-x-20 font-ocr uppercase text-[14px] md:text-[18px]'><span>Read More</span>  <span><FaArrowRightLong className='group-hover:rotate-45 duration-500 text-[14px] md:text-[16px]' /></span> </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className=" text-[12px] md:text-[16px] font-[poppins] mt-4 md:mt-0" data-aos-duration='2000' data-aos='fade-right'>
                            <p className="italic">"We Don't Just Tell Stories, We Create Legendary</p>
                            <p className="italic">Brand Experiences"</p>
                        </div>

                    </div>
                </section>




                {/*------ MD Sir Promo ---------- */}

                <section className='pt-10 md:pt-24  bg-black relative font-sans' id='third-section'>
                    <div className='w-full px-4 md:w-[80%] mx-auto' >
                        <div className='flex flex-col gap-y-10 md:flex-row items-center'>


                            <div className='w-full md:w-[50%] mx-auto md:px-4 pb-6'>

                                <div className="flex items-stretch gap-x-6 md:gap-x-16 h-full    ">

                                    {/* right Border */}
                                    <div className="flex relative flex-col items-center justify-center" data-aos="fade-down" data-aos-delay='500' data-aos-duration='4000'>
                                        <div className="border-l-2 md:border-l-4 border-white h-[30%] z-10" data-aos="fade-down" data-aos-duration='1000'></div>
                                        <div className=''>
                                            <Progressbar />
                                        </div>
                                        <div className="border-l border-white h-full absolute top-0 z-0"></div>
                                    </div>


                                    <div className='' data-aos="fade-up" data-aos-duration='1000'>
                                        <h2 className='text-[28px] md:text-[58px] text-white font-light tracking-[1px] font-sans  md:leading-[63px]' >Meet the Mind Behind the Magic</h2>

                                        <div className='mt-8 md:mt-20'>
                                            <p className='italic text-[12px] md:text-[16px] text-gray-200 font-[poppins]'>"The spark behind our wildest ideas. If you can dream it, He can make it happen"</p>
                                            <div className="border-t-2 border-gray-700 w-full mt-4"></div>

                                            <div className='text-white mt-8 md:mt-4 font-sans'>
                                                <h3 className='text-[24px] md:text-[32px] font-light tracking-[1px]'>Rajesh R</h3>
                                                <h3 className='text-[14px] md:text-[22px] tracking-[1px] text-gray-300'>Chairman and Managing Director</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className='w-full md:w-[50%] mx-auto' data-aos="fade-up" data-aos-duration='1000'>
                                <div className='flex justify-center md:justify-end items-end'>
                                    <img src={sir} className='w-full object-cover ' alt='sir' />
                                </div>
                            </div>

                        </div>
                    </div>
                </section>


                {/*-------------- why choose us ------------------*/}

                <section className='pt-10 md:pt-20 ' id='fourth-section'>
                    <div className='w-full px-4 md:w-[80%] pb-8 md:pb-10 mx-auto bg-gray-100 md:px-12 pt-6 md:pt-8  rounded-t-2xl font-sans' id='first-border'>

                        <div className="flex relative flex-col mb-4" data-aos="fade-right" data-aos-duration='2000' >
                            {/* Animated Border */}
                            <div className=" absolute top-0 flex border-t-2 md:border-t-4 w-[10%] border-black "></div>
                            <div className=''>
                                <BorderAnimation />
                            </div>
                            <div className="absolute flex justify-center items-center top-0 border-t border-black w-full"></div>
                        </div>

                        {/* WHY Title */}
                        <div className='flex flex-col gap-y-1 items-start font-triumvirate'>
                            <p className="text-gray-700 uppercase  tracking-wide font-sans text-[12px] md:text-[14px]">Why Choose Us</p>
                            <h2 className='text-[20px] md:text-[52px] text-black font-light  font-sans' data-aos="flip-up" data-aos-duration='3000'>Because Ordinary Isn't in Our Vocabulary</h2>
                        </div>

                        <div className='flex flex-col md:flex-row gap-y-10 justify-center items-center mt-5 md:mt-16'>
                            <div className='w-full md:w-[50%] mx-auto' data-aos="fade-up" data-aos-duration='1000'>
                                <p className='font-sans  text-[12px] md:text-[14px]  text-gray-900'>
                                    We believe that great ideas don't just live on paper - they come to life through
                                    collaboration, bold thinking, and a lot of coffee. We started as a small team with
                                    a big vision: to break through the noise with ads that spark conversation. Today, we
                                    partner with brands who want to create not just awareness, but a movement.
                                </p>

                                <div className='mt-6 md:mt-8 w-full '>
                                    <p className='italic font-semibold text-[12px] md:text-[14px] '>"We’re not here to simply follow trends. We set them."</p>

                                    {/* <div className='grid grid-cols-2 md:grid-cols-4 gap-x-2 gap-y-4 mb-8 mt-7 w-full'>

                                        <div className='flex flex-col items-center gap-y-3'>
                                            <div>
                                                <img src={whyOne} className='w-[50%] md:w-[50%] mx-auto' alt='none' />
                                            </div>
                                            <div>
                                                <h2 className='text-[11px] md:text-[12px] uppercase'>PASSION-Driven</h2>
                                            </div>
                                        </div>
                                        <div className='flex flex-col items-center gap-y-3'>
                                            <div>
                                                <img src={whyThree} className='w-[48%] md:w-[48%] mx-auto' alt='none' />
                                            </div>
                                            <div>
                                                <h2 className='text-[11px] md:text-[12px]  uppercase'>result-focused</h2>
                                            </div>
                                        </div>
                                        <div className='flex flex-col items-center gap-y-3 mt-2'>
                                            <div>
                                                <img src={whyTwo} className='w-[50%] md:w-[60%] mx-auto' alt='none' />
                                            </div>
                                            <div>
                                                <h2 className='text-[11px] md:text-[12px]  uppercase'> creativity</h2>
                                            </div>
                                        </div>

                                        <div className='flex flex-col items-center gap-y-3'>
                                            <div>
                                                <img src={whyFour} className='w-[50%] md:w-[50%] mx-auto' alt='none' />
                                            </div>
                                            <div>
                                                <h2 className='text-[11px] md:text-[12px]  uppercase'>impact</h2>
                                            </div>
                                        </div>

                                    </div> */}

                                    <div className='mb-8  mt-10 md:mt-7'>
                                        <WhyIcons />
                                    </div>

                                    <div>
                                        <TypingAnimation />
                                    </div>
                                    {/* <p className='italic font-semibold text-[14px] md:text-[14px] mt-8 '>"Our Success is Your Success, Your Goals Are Our Mission"</p> */}


                                </div>
                            </div>

                            <div className='w-full md:w-[50%] mx-auto' data-aos="fade-up" data-aos-delay='300' data-aos-duration='2000'>
                                <div className='flex justify-center items-center md:mb-9 relative'>
                                    <img src={innov} className='w-full md:w-[78%]  object-cover' alt='why' />
                                    <div className='absolute  top-6  md:top-7 4k:top-10 '>
                                        <div>
                                            <Eye />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>



                {/*------------ All services ----------*/}

                <section className='' id='fifth-section'>
                    <div id='second-border' class=" inset-0 -z-10 h-full w-full bg-stone-100 pt-10 pb-14 ">

                        <div className=' w-full mx-auto'>  {/* w-[90%]  if you need update width size */}

                            <div className='w-[95%] mx-auto flex flex-col gap-y-4 mb-8 md:mb-10 z-50'>
                                <div className="flex relative flex-col " data-aos="fade-right" data-aos-duration='1000'>
                                    <div className=" absolute top-0 flex border-t-2  md:border-t-4 w-[10%] border-black "></div>
                                    <div className=''>
                                        <BorderAnimation />
                                    </div>
                                    <div className="absolute flex justify-center items-center top-0 border-t border-black w-full"></div>
                                </div>

                                <div>
                                    <h3 className='text-[24px] md:text-[52px] text-black font-light z-50  font-sans'>Discover What We Offers</h3>
                                </div>
                            </div>

                            <div className='flex justify-center items-center w-full'>
                                {/* <AllServices /> */}
                                {/* <AllServicesNew /> */}

                                <AllServicesone />
                            </div>


                            {/* <div class="pt-12 flex justify-center font-[roboto] items-center">
                                <button class=" bg-purple-900 px-8 py-2 rounded-lg uppercase italic  text-white text-[14px] tracking-[1px]  ">
                                    Explore More
                                </button>
                            </div> */}

                            <div className='flex justify-center items-center  pt-12'>
                                <Link to='/services' onClick={topPage}>
                                    <button className="animated-btn px-10 py-2.5 rounded-lg hover:scale-105 duration-700 overflow-hidden bg-gray-950 hover:bg-purple-900 uppercase italic  text-white text-[13px] tracking-[1px]">
                                        <span className="top-span"></span>
                                        <span className="right-span"></span>
                                        <span className="bottom-span"></span>
                                        <span className="left-span"></span>
                                        Explore More
                                    </button>
                                </Link>
                            </div>



                        </div>
                    </div>
                </section>


                {/* -------------------- Our valuable clients ----------------- */}

                <section className='pt-10 md:pt-20 pb-7 md:pb-20  bg-gradient-to-b from-[#FAF9F6] to-white font-sans' id='sixth-section'>
                    <div className='w-full px-4 md:w-[90%] mx-auto relative' id='client-border'>


                        {/* <div className='mb-4 md:mb-6'>
                            <h2 className='text-[18px] md:text-[24px] font-extralight text-end  text-gray-800 ' data-aos="flip-up" data-aos-duration='1000'>They Trust Us</h2>
                        </div> */}

                        <div className=' mx-auto flex flex-col gap-y-4 mb-2 z-50'>
                            <div className="flex relative flex-col " data-aos="fade-right" data-aos-duration='1000'>
                                {/* Animated Border */}

                                <div className=" absolute top-0 flex  border-t-2 md:border-t-4 w-[10%] border-black "></div>
                                <div className=''>
                                    <BorderAnimation />
                                </div>
                                <div className="absolute flex justify-center items-center top-0 border-t border-black w-full"></div>
                            </div>

                            <div className='mb-4 md:mb-6'>
                                <h2 className='text-[14px] md:text-[24px] font-semibold text-start  text-gray-800 ' data-aos="flip-up" data-aos-duration='1000'>Our Clients</h2>
                            </div>
                        </div>

                        <div data-aos='fade-up' data-aos-duration='1000' className='relative'>
                            <Clients />

                            <div className='absolute -left-4 top-0 z-20' data-aos='fade-up' data-aos-duration='1000'>
                                <img src={corner} className='w-[12%] object-cover' alt='none' />
                            </div>
                        </div>

                    </div>
                </section>


                {/*----------- client approach --------------*/}

                <section className=' bg-gray-950  pb-10'>
                    <div className='w-full mx-auto pt-10 md:pt-10 font-sans'>


                        <div>
                            <div className='w-[90%] mx-auto flex flex-col gap-y-4 mb-2 z-50 font-[poppins]'>
                                <div className="flex relative flex-col " data-aos="fade-right" data-aos-duration='1000'>
                                    {/* Animated Border */}

                                    <div className=" absolute top-0 flex  border-t-2 md:border-t-4 w-[10%] border-white "></div>
                                    <div className=''>
                                        <BorderAnimation />
                                    </div>
                                    <div className="absolute flex justify-center items-center top-0 border-t border-white w-full"></div>
                                </div>

                                <div className='mb-4 md:mb-6'>
                                    <h2 className='text-[14px] md:text-[24px] font-semibold text-end  text-gray-100 uppercase ' data-aos="flip-up" data-aos-duration='1000'>Our Process</h2>
                                </div>
                            </div>
                        </div>



                        <div className='mt-4 md:mt-14'>
                            {/* <Process /> */}
                            <Allprocess />
                            {/* <AllProcessNew /> */}
                        </div>



                    </div>
                </section>





                <section className=' bg-gray-50 overflow-hidden relative font-sans'>
                    <div className=' mx-auto'>
                        <div>

                            <div className="">

                                {/* <div className="sticky top-36 z-10 h-[80vh] w-[92%] mx-auto px-10 py-20 bg-white rounded-3xl shadow-lg drop-shadow-sm ">
                                    <div className='flex items-stretch gap-x-6 h-full w-full'>

                                        <div className='flex justify-center items-center'>
                                            <div className='grid grid-cols-3 gap-x-16 w-full'>

                                                <div>
                                                    <div className='flex flex-col gap-y-6 justify-center items-center' data-aos='fade-up' data-aos-duration='1000'>
                                                        <div className='flex justify-center items-center'>
                                                            <img src={company} className='w-[70%] object-cover rounded-md' alt='brand' />
                                                        </div>
                                                        <div className='flex flex-col items-center gap-y-1 w-full'>
                                                            <h2 className='font-medium text-[16px] animate-bounce  duration-1000' data-aos='flip-up' data-aos-duration='1000'>Company Registration</h2>
                                                            <div className='border border-gray-400 w-full'></div>
                                                            <p className='text-[12px] italic font-[poppins]'>"Every big story begins with a single signature"</p>
                                                            <div>
                                                                <button data-aos='flip-up' data-aos-duration='1000' className='flex items-center gap-x-20 font-ocr uppercase border border-gray-300 rounded-full p-2 bg-white shadow-md drop-shadow-md'>
                                                                    <span><FaArrowRightLong /></span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div>
                                                    <div className='flex flex-col gap-y-6 justify-center items-center' data-aos='fade-up' data-aos-duration='2000'>
                                                        <div className='flex justify-center items-center'>
                                                            <img src={shoot} className='w-[70%] object-cover rounded-md' alt='brand' />
                                                        </div>
                                                        <div className='flex flex-col items-center gap-y-1 w-full'>
                                                            <h2 className='font-medium text-[16px] animate-bounce  duration-1000'>AD shoots</h2>
                                                            <div className='border border-gray-400 w-full'></div>
                                                            <p className='text-[12px] italic font-[poppins]'>"Every frame tells a story."</p>
                                                            <div>
                                                                <button className='flex items-center gap-x-20 font-ocr uppercase border border-gray-300 rounded-full p-2 bg-white shadow-md drop-shadow-md'>
                                                                    <span><FaArrowRightLong /></span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div>
                                                    <div className='flex flex-col gap-y-6 justify-center items-center' data-aos='fade-up' data-aos-duration='3000'>
                                                        <div className='flex justify-center items-center'>
                                                            <img src={sta} className='w-[70%] object-cover rounded-md' alt='brand' />
                                                        </div>
                                                        <div className='flex flex-col items-center gap-y-1 w-full'>
                                                            <h2 className='font-medium text-[16px] animate-bounce  duration-1000'>Strategy & Analytics</h2>
                                                            <div className='border border-gray-400 w-full'></div>
                                                            <p className='text-[12px] italic font-[poppins]'>"Success is found at the intersection of insight and action"</p>
                                                            <div>
                                                                <button className='flex items-center gap-x-20 font-ocr uppercase border border-gray-300 rounded-full p-2 bg-white shadow-md drop-shadow-md'>
                                                                    <span><FaArrowRightLong /></span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='h-full relative ' data-aos='fade-down' data-aos-duration='2000'>
                                            <div className='border-r-4   h-[20%] border-gray-800 '></div>
                                            <div className='border-r h-full border-gray-800 '></div>
                                        </div>

                                    </div>

                                </div>

                                <div className="sticky top-36 z-10 h-[80vh] w-[92%] mx-auto px-10 py-20 bg-white rounded-t-3xl shadow-lg drop-shadow-sm ">
                                    <div className='flex items-stretch gap-x-6 h-full w-full'>

                                        <div className='h-full relative ' data-aos='fade-down' data-aos-duration='2000'>
                                            <div className='border-l-4  h-[20%] border-gray-800 '></div>
                                            <div className='border-l h-full border-gray-800 '></div>
                                        </div>

                                        <div className='flex justify-center items-center'>
                                            <div className='grid grid-cols-3 gap-x-10 w-full'>

                                                <div>
                                                    <div className='flex flex-col gap-y-6 justify-center items-center' data-aos='fade-up' data-aos-duration='1000'>
                                                        <div className='flex justify-center items-center'>
                                                            <img src={tvradio} className='w-[70%] object-cover rounded-md' alt='brand' />
                                                        </div>

                                                        <div className='flex flex-col items-center gap-y-1 w-full'>
                                                            <h2 className='font-medium text-[16px] animate-bounce  duration-1000'>Television & FM Ad Releases</h2>
                                                            <div className='border border-gray-400 w-full'></div>
                                                            <p className='text-[12px] italic font-[poppins]'>"Make waves where everyone’s tuned in."</p>
                                                            <div>
                                                                <button className='flex items-center gap-x-20 font-ocr uppercase border border-gray-300 rounded-full p-2 bg-white shadow-md drop-shadow-md'>
                                                                    <span><FaArrowRightLong /></span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div>
                                                    <div className='flex flex-col gap-y-6 justify-center items-center' data-aos='fade-up' data-aos-duration='2000'>
                                                        <div className='flex justify-center items-center'>
                                                            <img src={digital} className='w-[70%] object-cover rounded-md' alt='brand' />
                                                        </div>

                                                        <div className='flex flex-col items-center gap-y-1 w-full'>
                                                            <h2 className='font-medium text-[16px] animate-bounce  duration-1000'>Digital Marketing</h2>
                                                            <div className='border border-gray-400 w-full'></div>
                                                            <p className='text-[12px] italic font-[poppins]'>"Be present where your audience lives."  </p>
                                                            <div>
                                                                <button className='flex items-center gap-x-20 font-ocr uppercase border border-gray-300 rounded-full p-2 bg-white shadow-md drop-shadow-md'>
                                                                    <span><FaArrowRightLong /></span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div>
                                                    <div className='flex flex-col gap-y-6 justify-center items-center' data-aos='fade-up' data-aos-duration='3000'>
                                                        <div className='flex justify-center items-center'>
                                                            <img src={webDev} className='w-[70%] object-cover rounded-md' alt='brand' />
                                                        </div>

                                                        <div className='flex flex-col items-center gap-y-1 w-full'>
                                                            <h2 className='font-medium text-[16px] animate-bounce  duration-1000'>Website & App Development</h2>
                                                            <div className='border border-gray-400 w-full'></div>
                                                            <p className='text-[12px] italic font-[poppins]'>"Your brand’s digital home”</p>
                                                            <div>
                                                                <button className='flex items-center gap-x-20 font-ocr uppercase border border-gray-300 rounded-full p-2 bg-white shadow-md drop-shadow-md'>
                                                                    <span><FaArrowRightLong /></span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="sticky top-36 z-10 h-[80vh] w-[92%] mx-auto px-10 py-20 bg-white rounded-t-3xl shadow-lg drop-shadow-sm ">
                                    <div className='flex items-stretch gap-x-6 h-full w-full'>



                                        <div className='flex justify-center items-center'>
                                            <div className='grid grid-cols-3 gap-x-10 w-full'>

                                                <div>
                                                    <div className='flex flex-col gap-y-6 justify-center items-center' data-aos='fade-up' data-aos-duration='1000'>
                                                        <div className='flex justify-center items-center'>
                                                            <img src={tvradio} className='w-[70%] object-cover rounded-md' alt='brand' />
                                                        </div>

                                                        <div className='flex flex-col items-center gap-y-1 w-full'>
                                                            <h2 className='font-medium text-[16px] animate-bounce  duration-1000'>Infrastructure Setup & Training</h2>
                                                            <div className='border border-gray-400 w-full'></div>
                                                            <p className='text-[12px] italic font-[poppins]'>"The best-built brands are made to last." </p>
                                                            <div>
                                                                <button className='flex items-center gap-x-20 font-ocr uppercase border border-gray-300 rounded-full p-2 bg-white shadow-md drop-shadow-md'>
                                                                    <span><FaArrowRightLong /></span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div>
                                                    <div className='flex flex-col gap-y-6 justify-center items-center' data-aos='fade-up' data-aos-duration='2000'>
                                                        <div className='flex justify-center items-center'>
                                                            <img src={digital} className='w-[70%] object-cover rounded-md' alt='brand' />
                                                        </div>

                                                        <div className='flex flex-col items-center gap-y-1 w-full'>
                                                            <h2 className='font-medium text-[16px] animate-bounce  duration-1000'>Product Sourcing & Finalization</h2>
                                                            <div className='border border-gray-400 w-full'></div>
                                                            <p className='text-[12px] italic font-[poppins]'>"Great brands deserve great products."  </p>
                                                            <div>
                                                                <button className='flex items-center gap-x-20 font-ocr uppercase border border-gray-300 rounded-full p-2 bg-white shadow-md drop-shadow-md'>
                                                                    <span><FaArrowRightLong /></span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div>
                                                    <div className='flex flex-col gap-y-6 justify-center items-center' data-aos='fade-up' data-aos-duration='3000'>
                                                        <div className='flex justify-center items-center w-full'>
                                                            <img src={webDev} className='w-[70%] object-cover rounded-md' alt='brand' />
                                                        </div>

                                                        <div className='flex flex-col items-center gap-y-1 w-full'>
                                                            <h2 className='font-medium text-[16px] animate-bounce  duration-1000'>Branding & Design</h2>
                                                            <div className='border border-gray-400 w-full'></div>
                                                            <p className='text-[12px] italic font-[poppins]'>"Your brand is more than a logo—it’s a legacy”</p>
                                                            <div>
                                                                <button className='flex items-center gap-x-20 font-ocr uppercase border border-gray-300 rounded-full p-2 bg-white shadow-md drop-shadow-md'>
                                                                    <span><FaArrowRightLong /></span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='h-full relative ' data-aos='fade-down' data-aos-duration='2000'>
                                            <div className='border-r-4  h-[20%] border-gray-800 '></div>
                                            <div className='border-r h-full border-gray-800 '></div>
                                        </div>

                                    </div>
                                </div> */}



                                {/*--------------------------------- blogs --------------------------*/}

                                <div className='sticky top-0 z-10  bg-white md:mt-20 ' id='thrid-border'>

                                    <section className='bg-gray-50  py-10 md:py-20 w-full font-sans'>


                                        <div className='w-full px-4 md:w-[80%] mx-auto'>

                                            <div className='w-full mx-auto flex flex-col gap-y-4 mb-8 md:mb-14'>
                                                <div className="flex relative flex-col  " data-aos='fade-right' data-aos-duration='1000'>
                                                    <div className=" absolute top-0 flex border-t-2 md:border-t-4 w-[10%] border-black "></div>
                                                    <div className=''>
                                                        <BorderAnimation />
                                                    </div>
                                                    <div className="absolute flex justify-center items-center top-0 border-t border-black w-full"></div>
                                                </div>

                                                <div>
                                                    <h3 className='text-black uppercase tracking-wide font-sans text-[12px] md:text-[14px]'>INSIGHTS THAT INSPIRE</h3>
                                                </div>
                                            </div>


                                            <div className='flex flex-col gap-y-24'>

                                                <div className='flex flex-col md:flex-row gap-y-8  items-center justify-center'>

                                                    <div className='w-full md:w-[50%] mx-auto'>
                                                        <div className="flex items-stretch gap-x-4 md:gap-x-12 h-full   text-white ">
                                                            {/* Left Border */}
                                                            <div className="flex relative flex-col " data-aos='fade-down' data-aos-duration='3000'>
                                                                <div className="border-l-2 md:border-l-4 border-black h-[20%] flex justify-center items-center"></div>
                                                                <div className="absolute flex justify-center items-center w-full border-l border-black h-full "></div>
                                                            </div>


                                                            <div className='flex justify-center items-center w-full md:px-10'>

                                                                <div className='flex flex-col gap-y-8 md:gap-y-14'>
                                                                    <h2 className='text-[20px] md:text-[34px]  text-black font-light' data-aos='flip-up' data-aos-duration='3000'>5 Marketing Trends You Can't Ignore in 2024</h2>
                                                                    <div className='border border-gray-600 w-full'></div>
                                                                    <p className='text-[12px] md:text-[16px] font-normal font-sans text-gray-800' data-aos='flip-up' data-aos-duration='3000'>
                                                                        Stay ahead of the curve with the top marketing trends. We'll show you
                                                                        how to incorporate these trends into your campaigns and keep your brand relevant.
                                                                    </p>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='w-full md:w-[50%] mx-auto'>
                                                        <div className=' flex justify-center md:justify-end items-center' data-aos-duration='3000' data-aos='fade-left'>
                                                            <img src={marketing} className='w-full md:w-[80%]  rounded-md drop-shadow-md object-cover' alt='blog' />
                                                        </div>
                                                    </div>

                                                </div>



                                                {/* ----------------------------------- */}


                                                <div className='flex flex-col-reverse gap-y-8 md:flex-row items-center justify-center'>

                                                    <div className='w-full md:w-[50%] mx-auto'>
                                                        <div className=' flex md:justify-start justify-center items-center' data-aos-duration='3000' data-aos='fade-right'>
                                                            <img src={idea} className='w-full md:w-[80%]  rounded-md drop-shadow-md object-cover' alt='blog' />
                                                        </div>
                                                    </div>


                                                    <div className='w-full md:w-[50%] mx-auto'>
                                                        <div className="flex items-stretch  gap-x-4 md:gap-x-12 h-full   text-white ">

                                                            <div className='flex justify-center items-center w-full md:px-10'>

                                                                <div className='flex flex-col gap-y-8 md:gap-y-14'>
                                                                    <h2 className='text-[20px] md:text-[34px]  text-black font-light text-end' data-aos='flip-up' data-aos-duration='3000'>Behind the Scenes: How We Create Magic</h2>
                                                                    <div className='border border-gray-600 w-full'></div>
                                                                    <p className='text-[12px] md:text-[16px] font-normal font-sans text-gray-800 text-end' data-aos='flip-up' data-aos-duration='3000'>
                                                                        Ever wondered what goes into a creative campaign? We pull back the curtain and show you how our team brings big
                                                                        ideas to life — from brainstorming sessions to the final launch.
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            {/* right Border */}
                                                            <div className="flex relative flex-col " data-aos='fade-up' data-aos-duration='3000'>
                                                                <div className="border-r-2 md:border-r-4 border-black h-[20%] flex justify-center items-center"></div>
                                                                <div className="absolute flex justify-center items-center w-full border-r border-black h-full "></div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                {/*----------- partners -----------*/}

                <section className='pt-10 md:pt-20 pb-10 md:pb-20 h-full bg-white' id='fourth-border'>
                    <div className='w-full px-4 md:w-[80%] mx-auto h-full' >


                        <div className=' mx-auto flex flex-col gap-y-4 mb-2 z-50'>
                            <div className="flex relative flex-col " data-aos="fade-right" data-aos-duration='1000'>
                                {/* Animated Border */}

                                <div className=" absolute top-0 flex border-t-2 md:border-t-4 w-[10%] border-black "></div>
                                <div className=''>
                                    <BorderAnimation />
                                </div>
                                <div className="absolute flex justify-center items-center top-0 border-t border-black w-full"></div>
                            </div>

                            <div className='mb-2 md:mb-6'>
                                <h2 className='text-[14px] font-semibold md:text-[24px]  text-start  text-gray-800 ' data-aos="flip-up" data-aos-duration='1000'>Our Partners</h2>
                            </div>
                        </div>

                        <div className="flex justify-center items-center w-full ">
                            <div className='w-full h-full'>
                                <Partners />
                            </div>
                        </div>

                    </div>
                </section>


                {/*-------- Email Subscribe --------*/}


                <section className='pt-10 md:pt-20 pb-10 md:pb-20 font-sans z-10 bg-black' id='seventh-section'>
                    <div className='w-full px-4 md:w-[80%] mx-auto'>

                        <div className="flex relative flex-col mb-2 md:mb-4" data-aos="fade-right" data-aos-duration='1000'>
                            <div className="border-t-2 md:border-t-4 border-white w-[10%] flex justify-center items-center"></div>

                            <div className="absolute flex justify-center items-center top-0 border-t border-white w-full"></div>
                        </div>


                        {/* ABOUT Title */}

                        <div className='flex flex-col gap-y-10 md:flex-row justify-center items-center w-full mt-8 md:mt-12'>

                            <div className='w-full md:w-[60%] mx-auto'>
                                <div className="flex items-stretch gap-x-6 md:gap-x-12 h-full   text-white ">

                                    <div className="flex relative flex-col ">
                                        <div className="border-l-2 md:border-l-4 border-white h-[20%] flex justify-center items-center"></div>
                                        <div className="absolute flex justify-center items-center w-full border-l border-white h-full "></div>
                                    </div>

                                    <div>

                                        <div className='flex flex-col gap-y-3 md:gap-y-1 items-start font-triumvirate'>
                                            <p className="text-white uppercase tracking-wide font-sans text-[12px] md:text-[14px]">Contact</p>
                                            <h2 className='text-[24px] md:text-[54px] text-white font-extralight font-sans' data-aos="flip-up" data-aos-duration='2000'> What's your dream project? </h2>
                                            <p className='text-[14px] md:text-[20px] text-white font-extralight italic font-[poppins]'>We want to hear about your vision - the bigger, the better!</p>
                                        </div>

                                        <div className='w-full mt-16 md:mt-24'>
                                            <input
                                                className="px-2 py-1.5 text-[12px] bg-transparent border-b border-gray-50 w-full  focus:outline-none focus:border-transparent text-white md:text-[14px]"
                                                placeholder="Enter Your Email"
                                            />
                                        </div>
                                        <div className='flex justify-end mt-3'>
                                            <button className='px-4 py-1.5 rounded-md bg-white font-ocr text-[12px] md:text-[14px] text-black' data-aos="flip-up" data-aos-duration='2000'>Let's Make Magic Happen</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='w-full md:w-[40%] mx-auto'>

                                <div data-aos-duration='3000' data-aos='fade-up'>
                                    <img src={contact} className='w-full object-cover ' alt='message' />
                                </div>

                            </div>
                        </div>
                    </div>
                </section>

            </div>

        </>
    )
}

export default Home